.hero_container{
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background:transparent;
    max-width:1280px;
    margin: 0 auto;
}

.hero_inner{
    width:600px;
    height: 300px;
    border-radius: 10px;
    background-color: rgb(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero_inner button{
    background-color: transparent;
    color:white;
    border: 1px solid white;
    padding:10px;
    border-radius: 5px;
    margin-top: 20px;
}

.hero_inner button:hover{
    background-color: white;
    color: black;
    cursor: pointer;
}

.hero_inner h1{
    color: white;
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;  
    font-weight: 1000;
    font-size: 2rem;
    text-align: center;
    background: linear-gradient(to right, hsl(0,0%,30%)0, hsl(0,0%,100%) 10%, hsl(0,0%,30%) 20%);
    -webkit-background-clip:text;
    animation: shine 3s infinite linear;
    /* padding: 10px; */
}

@keyframes shine {
    0%{
        background-position: 0;
    }
    60%{
        background-position:600px;
    }
    100%{
        background-position: 900px;
    }
}
.mint_container{
    display:flex;
    align-items:center;justify-content: center;
    flex-direction: column;
 
}
.mint_container div{
    display:flex;
    align-items: center;
    justify-content: center;
     
}
.mint_container input{
    color: white;
    height: 30px;
    width:30px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    background-color: transparent;
    border: none;
    font-weight: bolder;
    font-size: 2rem;
    margin-top: 20px;
    font-family:  'Roboto Mono', monospace;
}

.hero_inner p{
    font-family:'Roboto Mono', monospace;
    margin-top:20px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media screen and (max-width:770px) {
    .hero_inner{
        width:500px;
    }
}
.App {
  background:center / cover no-repeat url('../public/leprechaunBg.jfif');
  height: 100vh;
}
@media screen and (max-width:770px) {
  .App{
    height:100vh;
  }
}



.slider{
    height:200px;
    margin:auto;
    position:relative;
    width:100%;
    display: grid;
    place-items: center;
    overflow: hidden;
    background-color: rgb(0,0,0);
}

.slide-track{
    display: flex;
    width: calc(250px * 10);
    animation: scroll 40s linear infinite;
}

@keyframes scroll {
    0%{
        transform:translateX(0)
    }
    100%{
        transform: translateX(calc(-250px * 9));
    }
}
.slide{
    height: 200px;
    width:250px;
    display: flex;
    align-items: center;
    padding:10px;
}
 img{
    width:100%;
    height: 100%;
    object-fit: contain;
}
@media screen and (max-width:770px) {
    .slider{
        margin-top:-23px;
        height:140px;
    }
    img{
        width:100%;
        height:60%;
        border-radius:10px;
        margin-top: -80px;
        /* margin-right: -100px; */
    }
}

.header_container{
    background-color:RGB(0,0,0,0.8);
}

.header_container button{
    background-color: transparent;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid white;
    font-weight: 800px;
    font-family: 'Roboto Mono', monospace;
}

.mobile_nav{
    display:none;
}

.active{
    display:inline-block;
}


.header_inner{
    max-width: 1280px;
    height:100px;
    width:100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.right_nav p{
    font-family:'Roboto Mono', monospace;
}

.header_inner .logo{
    color: white;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;  
    font-weight: 1000;
    font-size: 2rem;
    width:150px;
}


.header_inner button{
    background-color: transparent;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid white;
    font-weight: 800px;
    font-family: 'Roboto Mono', monospace;
}

.header_inner button:hover{
    color: black;
    background-color: white;
}
.header_inner .right_nav{
    display: flex;
    width:280px;
    justify-content:space-evenly;
    align-items: center;
}
.hamburger{
    display: none;
}

/* @media screen and ma {
    
} */
@media screen and (max-width:770px){
    .header_container{
        background-color:RGB(0,0,0,1);
    }
    .header_inner .logo{
    color: white;
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;  
    font-weight: 1000;
    font-size: 1.5rem;
    width:50px;
    }
    .mobile_nav{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    /* .mobile_nav */
    .header_inner{
        height: 60px;
    }
    .header_inner .right_nav{
        display:none;
    }
    .hamburger{
        display: block;
        padding-right:5vw;
    }
}